<div class="wrapper wrapper-full-page">
  <div class="page-header lock-page header-filter"
    style="background-image: url('../../assets/img/bg9.jpg'); background-size: cover; background-position: top center;">
    <div class="container">
      <div class="col-md-6 ml-auto mr-auto text-center">
        <h2>Error: Page Not Found</h2>
        <h1 class="text-status">404</h1>
        <p>Sorry,access to this resource on the server is denied</p>
        <p>Either check the URL or <a href="/dashboard">go home</a></p>
      </div>
    </div>
  </div>
</div>