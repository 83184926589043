import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class AuthGuardService {


  constructor(public router: Router, private oauthService: OAuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // Check user login

    if (this.oauthService.getIdentityClaims()) {
      return true;
    }
    // not logged in so redirect to login page
    this.oauthService.logOut()
    return false;
  }

}
