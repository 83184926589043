import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthGuardService } from './services/auth.guard.service';
import { PrivilegeGuardService } from './services/privilege-guard.service';
import { NotfoundComponent } from './components/notfound/notfound.component';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'rooms',
        loadChildren: () => import('./components/rooms/rooms.module').then(m => m.RoomsModule)
      },
      {
        path: 'organize',
        canActivateChild: [PrivilegeGuardService],
        data: {
          privilege: ['create', 'approve'],
        },
        loadChildren: () => import('./components/organize/organize.module').then(m => m.OrganizeModule)
      },
      {
        path: 'teams',
        canActivateChild: [PrivilegeGuardService],
        data: {
          privilege: ['create', 'approve'],
        },
        loadChildren: () => import('./components/teams/teams.module').then(m => m.TeamsModule)
      },
      {
        path: 'users',
        canActivateChild: [PrivilegeGuardService],
        data: {
          privilege: ['create'],
        },
        loadChildren: () => import('./components/users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'profile',
        loadChildren: () => import('./components/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'settings',
        canActivateChild: [PrivilegeGuardService],
        data: {
          privilege: ['create'],
        },
        loadChildren: () => import('./components/settings/settings.module').then(m => m.SettingsModule)
      },



      {
        path: "dictionary",
        canActivate: [AuthGuardService],
        data: {
          privilege: ['create'],
        },
        loadChildren: () => import('./components/dictionary/dictionary.module').then(m => m.DictionaryModule)
      }
    ]
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule)
      }
    ]
  },
  { path: '**', component: NotfoundComponent }
];
