// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  API_URL: "https://api.sandbox.rtt.in.th/v3",
  API_WS: "",
  NAME: "Transcription",
  ROOM_STATUS: [
    "Unknow",
    "Reserved",
    "Approved",
    "Waiting",
    "Streaming",
    "Paused",
    "EOS",
    "Idle",
    "Unused",
    "End",
    "Cancel"
  ],
  ROOM_TYPE: [
    "unknow",
    "ห้องถ่ายทอดสด",
    "ห้องฝึกพิมพ์กับบอท",
    "ห้องสำหรับทดสอบ (ไม่เก็บ Log)",
    "ห้องรีเพลย์ (แบบไม่วนซ้ำ)",
    "ห้องรีเพลย์ (แบบวนซ้ำ)",
    "ห้องพูดทวน",
    "Bot แบ่งพิมพ์",
    "ASR",
    "Bot แบ่งพิมพ์",
    "Bot ASR",
    "Bot พูดทวน"
  ],
  OAUTH: {
    URL: 'https://accounts.sandbox.rtt.in.th/auth/realms/rtt',
    CLIENT_ID: 'crm',
    RESONSE_TYPE: 'code'
  },
  Dictionary_API: 'https://api.sandbox.rtt.in.th/dictionary/v1',
  ASSETS_URL: 'https://assets.sandbox.rtt.in.th',
  API_MANAGEMENT: 'https://management-api.sandbox.rtt.in.th/v1/',
  API_ACCOUNT: 'https://account-api.sandbox.rtt.in.th/v1/',
};
