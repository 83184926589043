import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../environments/environment';
import { User } from '../../interfaces/user.interface';
import { OAuthService } from 'angular-oauth2-oidc';
declare const $: any;

@Component({
  selector: 'app-sidebar-cmp',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public user: User;
  systemName: string;
  constructor(private authService: AuthService,
    private oauthService: OAuthService,
  ) {
    this.systemName = environment.NAME || 'Angular Core';
  }
  // initial menu items list
  ngOnInit() {
    this.authService.currentUser.subscribe((res: User) => {
      this.user = res;
      this.user.photo = res.photo || '/assets/img/default-avatar.png';
    });
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }

  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>(
        document.querySelector('.sidebar .sidebar-wrapper')
      );
      const ps = new PerfectScrollbar(elemSidebar, {
        wheelSpeed: 2,
        suppressScrollX: true
      });
    }
  }

  // validatePrivilege(privilege) {
  //   console.log(privilege)
  //   console.log(this.user)
  //   if (
  //     (privilege.broadcast === this.user.privilege.broadcast &&
  //       privilege.broadcast) ||
  //     (privilege.transcribe === this.user.privilege.transcribe &&
  //       privilege.transcribe) ||
  //     (privilege.create === this.user.privilege.create && privilege.create) ||
  //     (privilege.reserve === this.user.privilege.reserve && privilege.reserve) ||
  //     (privilege.approve === this.user.privilege.approve && privilege.approve) ||
  //     (privilege.bot === this.user.privilege.bot && privilege.bot)
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }

  validatePrivilege(privilege) {
    var isCheck = false;
    this.user['realm_access'].roles.forEach(element => {
      var check = privilege.includes(element);
      if (check) {
        isCheck = true;
      }
    });
    return isCheck
  }

  validatePrivilegeSub(privilege) {
    if (privilege == null) {
      return true
    }
    var isCheck = false;
    this.user['realm_access'].roles.forEach(element => {
      var check = privilege.includes(element);
      if (check) {
        isCheck = true;
      }
    });
    return isCheck
  }


  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf('MAC') >= 0 ||
      navigator.platform.toUpperCase().indexOf('IPAD') >= 0
    ) {
      bool = true;
    }
    return bool;
  }

  logout() {
    // this.keycloakService.logout();
    this.oauthService.logOut();
  }
}

// Menu Items
export const ROUTES: RouteInfo[] = [
  // {
  //   path: "/request/import",
  //   title: "ยื่นขอจัดทำ Closed Caption",
  //   type: "link",
  //   icontype: "closed_caption",
  //   privilege: {
  //     broadcast: 1,
  //     transcribe: 1,
  //     create: 1,
  //     reserve: 1,
  //     approve: 1,
  //     bot: 1
  //   },
  //   children: []
  // },
  // {
  //   path: '/rooms/reserve',
  //   title: 'ขอจองใช้ระบบถอดความเสียง',
  //   type: 'link',
  //   icontype: 'create',
  //   privilege: ['reserve'],

  //   children: []
  // },
  // {
  //   path: "/rooms/me",
  //   title: "ห้องถ่ายทอดสดของคุณ",
  //   type: "link",
  //   icontype: "view_list",
  //   privilege: {
  //     broadcast: 0,
  //     transcribe: 0,
  //     create: 0,
  //     reserve: 1,
  //     approve: 0,
  //     bot: 0
  //   },
  //   children: []
  // },
  {
    path: '/profile',
    title: 'ข้อมูลส่วนตัว',
    type: '',
    icontype: '',
    privilege: [
      'broadcast',
      'transcribe',
      'create',
      'reserve',
      'approve',
      'bot',
    ],
  },
  {
    path: '/rooms/create',
    title: 'สร้างห้อง',
    type: 'sub',
    icontype: 'assignment',
    collapse: 'reservation_system',
    privilege: ['create', 'approve'],
    children: [
      {
        path: 'live',
        title: 'ถอดความจากเสียง',
        ab: '-',
        privilegeSub: null
      },
      {
        path: 'bot',
        title: 'ปล่อยข้อความอัตโนมัติ',
        ab: '-',
        privilegeSub: null
      },
      // {
      //   path: 'requestcc',
      //   title: 'ถอดความเสียงย้อนหลัง',
      //   ab: '-',
      //   privilegeSub: null
      // }
    ]
  },
  {
    path: '/rooms/list',
    title: 'จัดการห้อง',
    type: 'sub',
    icontype: 'list',
    collapse: 'liveroom',
    privilege: ['create', 'approve', 'reserve'],
    children: [
      {
        path: 'reserve',
        title: 'รอการอนุมัติ',
        ab: '-',
        privilegeSub: ['create', 'approve'],
      },
      // {
      //   path: "bot",
      //   title: "ห้องฝึกพิมกับบอท",
      //   ab: "-",
      //   privilegeSub: null
      // },
      {
        path: 'live',
        title: 'ถ่ายทอดสด',
        ab: '-',
        privilegeSub: ['create', 'approve', 'reserve'],
      },
      {
        path: 'end',
        title: 'สิ้นสุดการบริการ',
        ab: '-',
        privilegeSub: ['create', 'approve'],
      }
    ]
  },
  {
    path: '/dictionary',
    title: 'จัดการคำศัพท์',
    type: 'sub',
    icontype: 'menu_book',
    collapse: 'dictionary',
    privilege: [
      'create', 'approve'
    ],
    children: [
      {
        path: 'create',
        title: 'สร้างไฟล์รายการคำศัพท์',
        ab: '-',
        privilegeSub: null
      },
      {
        path: 'manage',
        title: 'จัดการคำศัพท์',
        ab: '-',
        privilegeSub: null
      },

    ]
  },
  {
    path: '/organize/create',
    title: 'สร้างหน่วยงาน',
    type: '',
    icontype: '',
    privilege: ['create', 'approve'],
  },
  {
    path: '/organize/edit',
    title: 'แก้ไขหน่วยงาน',
    type: '',
    icontype: '',
    privilege: ['create', 'approve'],
  },
  {
    path: '/organize/list',
    title: 'จัดการหน่วยงาน',
    type: 'link',
    icontype: 'apartment',
    collapse: 'organize',
    privilege: ['create', 'approve'],
    // children: [
    //   {
    //     path: 'create',
    //     title: 'เพิ่มหน่วยงาน',
    //     ab: '-',
    //     privilegeSub: null
    //   },
    //   {
    //     path: 'list',
    //     title: 'หน่วยงานทั้งหมด',
    //     ab: '-',
    //     privilegeSub: null
    //   }
    // ]
  },
  {
    path: '/teams/create',
    title: 'สร้างทีม',
    type: '',
    icontype: '',
    privilege: ['create', 'approve'],
  },
  {
    path: '/teams/edit',
    title: 'แก้ไขทีม',
    type: '',
    icontype: '',
    privilege: ['create', 'approve'],
  },
  {
    path: '/teams/list',
    title: 'จัดการทีมถอดความ',
    type: 'link',
    icontype: 'people',
    collapse: 'userteam',
    privilege: ['create', 'approve'],
    // children: [
    //   {
    //     path: 'create',
    //     title: 'เพิ่มทีม',
    //     ab: '-',
    //     privilegeSub: null
    //   },
    //   {
    //     path: 'list',
    //     title: 'ทีมทั้งหมด',
    //     ab: '-',
    //     privilegeSub: null
    //   }
    // ]
  },
  {
    path: '/users/create',
    title: 'สร้างผู้ใช้งาน',
    type: '',
    icontype: '',
    privilege: ['create', 'approve'],
  },
  {
    path: '/users/edit',
    title: 'แก้ไขผู้ใช้งาน',
    type: '',
    icontype: '',
    privilege: ['create', 'approve'],
  },
  {
    path: '/users/list',
    title: 'จัดการผู้ใช้งานระบบ',
    type: 'link',
    icontype: 'account_circle',
    collapse: 'users',
    privilege: ['create'],
    children: [
      {
        path: 'create',
        title: 'เพิ่มข้อมูลผู้ใช้งาน',
        ab: '-',
        privilegeSub: null
      },
      {
        path: 'list',
        title: 'ผู้ใช้งานทั้งหมด',
        ab: '-',
        privilegeSub: null
      }
    ]
  },
  {
    path: '/settings',
    title: 'ตั้งค่าระบบ',
    type: 'sub',
    icontype: 'settings',
    collapse: 'settings',
    privilege: ['create'],
    children: [
      {
        path: 'options',
        title: 'รายการการตั้งค่าเบื้องต้น',
        ab: '-',
        privilegeSub: null
      },
      {
        path: 'preoption',
        title: 'ชุดการตั้งค่าเบื้องต้น',
        ab: '-',
        privilegeSub: null
      },
    ]
  },
  {
    path: '/logout',
    title: 'ออกจากระบบ',
    type: 'logout',
    icontype: 'lock',
    privilege: [
      'broadcast',
      'transcribe',
      'create',
      'reserve',
      'approve',
      'bot',
    ],
    children: []
  },


];

// Metadata
interface RouteInfo {
  path: string;
  title: string;
  type?: string;
  icontype: string;
  collapse?: string;
  children?: ChildrenItems[];
  privilege?: String[];
}

interface Privilege {
  broadcast: number;
  transcribe: number;
  create: number;
  reserve: number;
  approve: number;
  bot: number;
}

interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
  privilegeSub?: String[];
}
