import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from '../interfaces/user.interface';
@Injectable()
export class UserService {
  baseUrl: string;
  constructor(private http: HttpClient) {
    this.baseUrl = environment.API_URL;
  }

  getUserAll() {
    return this.http.get(`${environment.API_ACCOUNT}users`)
  }

  createUser(body) {
    return this.http.post(`${environment.API_ACCOUNT}users`, body)
  }

  updateUser(id, body) {
    delete body.new_password;
    return this.http.put(`${environment.API_ACCOUNT}users/${id}`, body)
  }

  getUserById(id: number) {
    return this.http.get(`${environment.API_ACCOUNT}users/${id}`)
  }

  resetpassword(id, body) {
    return this.http.put(`${environment.API_ACCOUNT}users/${id}/reset-password`, body)
  }

  // updateUser(id: number, body: object) {
  //   return this.http.put(this.baseUrl + '/users/' + id, body);
  // }

  // createUser(body: object) {
  //   return this.http.post(this.baseUrl + '/users', body);
  // }

  deleteUserById(id: number) {
    return this.http.delete(`${environment.API_ACCOUNT}users/${id}`)
  }

  getUsers(filter?: any) {
    if (filter) {
      if (filter.search === 'transcribe' || filter.search === 'owner') {
        // filter users privilege is agent or broadcaster
        return this.http.get(this.baseUrl + '/users?search=' + filter.search);
      }
      // filter users and pagination
      return this.http.get(
        this.baseUrl +
        '/users?page=' +
        filter.page +
        '&limit=' +
        filter.limit +
        '&search=' +
        filter.search +
        '&keyword=' +
        filter.keyword
      );
    }
    return this.http.get(this.baseUrl + '/users');
  }

  getListUserKeyCloak() {
    return this.http.get(`${this.baseUrl}/users/keycloak`)
  }
}
