<div class="logo">
    <div class="row">
        <!-- <div class="col-4 text-right custom-pd">
            <img src="assets/img/test3.svg" class="custom-logo">
        </div>
        <div class="col-8 pl-2">
            <a href="#" class="simple-text logo-normal">
                {{systemName}}
            </a>
        </div> -->
        <div class="box-logo">
            <img src="assets/img/web-logo.png" class="custom-logo">
        </div>
    </div>
    <!-- <div class="row">
     
    </div>
    -->


</div>



<div class="sidebar-wrapper">
    <div class="user">
        <div class="photo">
            <!-- <img src="./assets/img/faces/avatar.jpg" /> -->
            <img [src]="user.photo" id="profileImg" />
        </div>
        <div class="user-info">
            <a data-toggle="collapse" href="#collapseExample" class="collapsed">
                <span>
                    {{user.firstname}}
                    <b class="caret"></b>
                </span>
            </a>

            <div class="collapse" id="collapseExample">
                <ul class="nav">
                    <li class="nav-item">
                        <a routerLink="/profile" class="nav-link">
                            <span class="sidebar-mini">-</span>
                            <span class="sidebar-normal">ข้อมูลส่วนตัว</span>
                        </a>
                    </li>
                </ul>
            </div>

        </div>
    </div>


    <ul class="nav">
        <li routerLinkActive="active" *ngFor="let menuitem of menuItems" class="nav-item">

            <!-- validate privilege -->
            <ng-container *ngIf="validatePrivilege(menuitem.privilege)">
                <!--If is a single link-->
                <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'" class="nav-link">
                    <i class="material-icons">{{menuitem.icontype}}</i>
                    <p>{{menuitem.title}}</p>
                </a>

                <a (click)="logout()" *ngIf="menuitem.type === 'logout'" class="nav-link" style="cursor: pointer;">
                    <i class="material-icons">{{menuitem.icontype}}</i>
                    <p>{{menuitem.title}}</p>
                </a>


                <!--If it have a submenu-->
                <a data-toggle="collapse" href="#{{menuitem.collapse}}" *ngIf="menuitem.type === 'sub'"
                    (click)="updatePS()" class="nav-link">
                    <i class="material-icons">{{menuitem.icontype}}</i>
                    <p>{{menuitem.title}}<b class="caret"></b></p>
                </a>

                <!--Display the submenu items-->
                <div id="{{menuitem.collapse}}" class="collapse" *ngIf="menuitem.type === 'sub'">
                    <ul class="nav">
                        <li routerLinkActive="active" *ngFor="let childitem of menuitem.children" class="nav-item">
                            <a [routerLink]="[menuitem.path, childitem.path]" class="nav-link"
                                *ngIf="validatePrivilegeSub(childitem.privilegeSub)">
                                <span class="sidebar-mini">{{childitem.ab}}</span>
                                <span class="sidebar-normal">{{childitem.title}}</span>

                            </a>
                        </li>
                    </ul>
                </div>
            </ng-container>
        </li>
    </ul>

</div>